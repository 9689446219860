import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button, ButtonLink } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/common.css';
import {
  requireCheck,
  resetRequired,
  validateGst,
  randomString,
  isDummyMail,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  checkNumber,
  authCheck,
  ADMIN,
  initialPageAccess,
  screenHeadingMap,
} from '../../utils/utils';
import toast, { Toaster } from 'react-hot-toast';
import { Checkbox } from '@paljs/ui';

const SelectField = styled(Select)`
  width: 100%;
`;
export default function ScreenAccess() {
  const [defaultAccess, setDefaultAccess] = useState(initialPageAccess);
  const [id, setId] = useState('');
  const [emailOptions, setEmailOptions] = useState([]);
  const authState = useContext(GlobalStateData);
  const getApi = () => {
    showLoader();
    try {
      axios
        .get(`${baseUri()}users`, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          const optionsArr: any = [];
          response.data.forEach((t: any) => {
            optionsArr.push({
              label: `${t.email}`,
              value: t.id,
            });
          });
          setEmailOptions(optionsArr);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const [accessArr, setAccessArr] = useState([]);
  const currAccessArr: any = [];
  const clearFormData = () => {
    setDefaultAccess(initialPageAccess);
    setId('');
  };
  const accessArraySetter = () => {
    for (const key in defaultAccess) {
      currAccessArr.push({
        title: key,
        ...defaultAccess[key],
      });
    }
    setAccessArr(currAccessArr);
  };
  const handleChange = (e, idx, key) => {
    const currAccessArr = [...accessArr];
    let currValue = 0;
    if (e) {
      currValue = 1;
    }
    currAccessArr[idx][key] = currValue;
    setAccessArr(currAccessArr);
  };

  const postApi = () => {
    const pageAccess = {};
    accessArr.forEach((t) => {
      let currAccess = {
        read: t.read,
        write: t.write,
        delete: t.delete,
        edit: t.edit,
      };
      pageAccess[t.title] = currAccess;
    });
    delete pageAccess.id;
    showLoader();
    axios
      .put(
        `${baseUri()}users/${id}`,
        { pageAccess },
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        clearFormData();
        hideLoader();
      });
  };
  useEffect(() => {
    showLoader();
    getApi();
  }, []);
  useEffect(() => {
    accessArraySetter();
  }, [defaultAccess]);
  useEffect(() => {
    if (id === '') {
      setDefaultAccess(initialPageAccess);
    } else {
      axios
        .get(`${baseUri()}users/${id}?populate[0]=pageAccess`, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          response = response.data;
          if (response.pageAccess) {
            delete response.pageAccess.id;
            setDefaultAccess(response.pageAccess);
          } else {
            setDefaultAccess(initialPageAccess);
          }
        });
    }
  }, [id]);
  return (
    <>
      <Card>
        <div
          style={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
            gap: '15px',
            alignItems: 'center',
          }}
        >
          <SelectField
            options={emailOptions}
            placeholder="Enter email"
            onChange={(event: any) => {
              setId(event.value);
            }}
            className="email id"
          />
          <Button status="Success" onClick={postApi} disabled={id === ''}>
            Save
          </Button>
        </div>
      </Card>
      <Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 1rem',
          }}
        >
          <h3>Screen Permissions</h3>
          <Button onClick={accessArraySetter}>Reset</Button>
        </div>
        <div
          style={{
            padding: '5px 15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-evenly',
              gap: '10px',
              alignItems: 'center',
              height: '30px',
              borderBottom: '1px solid black',
              borderTop: '1px solid black',
            }}
          >
            <h6
              style={{
                width: '5%',
                overflow: 'hidden',
                borderRight: '1px solid black',
                textAlign: 'center',
                paddingTop: '5px',
              }}
            >
              Read
            </h6>
            <h6
              style={{
                width: '5%',
                overflow: 'hidden',
                borderRight: '1px solid black',
                textAlign: 'center',
                paddingTop: '5px',
              }}
            >
              Write
            </h6>
            <h6
              style={{
                width: '5%',
                overflow: 'hidden',
                borderRight: '1px solid black',
                textAlign: 'center',
                paddingTop: '5px',
              }}
            >
              Edit
            </h6>
            <h6
              style={{
                width: '5%',
                overflow: 'hidden',
                borderRight: '1px solid black',
                textAlign: 'center',
                paddingTop: '5px',
              }}
            >
              Delete
            </h6>
            <h6
              style={{
                width: '40%',
                overflow: 'hidden',
                paddingTop: '5px',
              }}
            >
              Pages
            </h6>
          </div>
          {accessArr.map((t, idx) => (
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-evenly',
                gap: '10px',
                alignItems: 'center',
                borderBottom: '1px solid black',
              }}
            >
              <div
                style={{
                  width: '5%',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  paddingTop: '5px',
                }}
              >
                <Checkbox checked={t.read === 1} onChange={(e) => handleChange(e, idx, 'read')} />
              </div>
              <div
                style={{
                  width: '5%',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  paddingTop: '5px',
                }}
              >
                <Checkbox checked={t.write === 1} onChange={(e) => handleChange(e, idx, 'write')} />
              </div>
              <div
                style={{
                  width: '5%',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  paddingTop: '5px',
                }}
              >
                <Checkbox checked={t.edit === 1} onChange={(e) => handleChange(e, idx, 'edit')} />
              </div>
              <div
                style={{
                  width: '5%',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  paddingTop: '5px',
                }}
              >
                <Checkbox checked={t.delete === 1} onChange={(e) => handleChange(e, idx, 'delete')} />
              </div>
              <div>
                <h7>{screenHeadingMap[t.title]}</h7>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
}
